<template>
  <div>
    <h2 class="blue-grey--text nunito lighten-3"><b>Details</b></h2>
    <v-divider class="mb-2"></v-divider>
    <v-card outlined color="transparent">
      <v-container grid-list-lg fluid class="nunito">
        <v-row>
          <v-col cols="6">
            <v-card-title primary-title class="font-weight-bold">
              Created since
            </v-card-title>
            <v-card-text>
              {{
                merchant_detail.created_at
                  ? returnDate(merchant_detail.created_at)
                  : "-"
              }}</v-card-text
            >
          </v-col>
          <v-col cols="6">
            <v-card-title primary-title class="font-weight-bold">
              Expire at
            </v-card-title>
            <v-card-text>
              <p>
                {{
                  merchant_detail.expired_at
                    ? returnDate(merchant_detail.expired_at)
                    : "-"
                }}
              </p>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card-title primary-title class="font-weight-bold">
              Last Update Account Setting
            </v-card-title>
            <v-card-text>
              {{
                merchant_detail.updated_at
                  ? returnDate(merchant_detail.updated_at)
                  : "-"
              }}</v-card-text
            >
          </v-col>
          <v-col cols="6" v-if="merchant_detail.v2">
            <v-card-title primary-title class="font-weight-bold">
              Account Plan
            </v-card-title>
            <v-card-text>
              {{ merchant_detail.plan.name || "Trial" }}</v-card-text
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card-title primary-title class="font-weight-bold">
              Shop
            </v-card-title>
            <v-card-text>
              <div class="flex flex-column">
                <div class="flex mb-2">
                  <v-avatar size="32" color="light-blue">
                    <img
                      v-if="merchant_detail.logo"
                      :src="`https://s3-ap-southeast-1.amazonaws.com/assets.getorders/${merchant_detail.logo}`"
                      :alt="merchant_detail.name.charAt(0)"
                    />
                    <span v-else>{{ merchant_detail.name.charAt(0) }}</span>
                  </v-avatar>
                  <span class="font-weight-bold my-auto ml-3">{{
                    merchant_detail.name
                  }}</span>
                </div>
                <div class="mb-2">
                  <v-icon color="light-grey">mdi-account</v-icon>
                  <span class="ml-3 my-auto">{{
                    merchant_detail.personal_name || "Not Set"
                  }}</span>
                </div>
                <div class="mb-2">
                  <v-icon color="light-green">mdi-whatsapp</v-icon>
                  <span class="ml-3 my-auto">{{ merchant_detail.phone }}</span>
                </div>
                <div class="mb-2">
                  <v-icon color="teal">mdi-store</v-icon>
                  <span class="ml-3 my-auto">
                    <a
                      :href="`https://getorders.app/#${merchant_detail.short_id}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      getorders.app/#{{ merchant_detail.short_id }}
                    </a>
                  </span>
                </div>
                <div class="mb-2">
                  <v-icon color="blue-grey lighten-1"
                    >mdi-map-marker-outline</v-icon
                  >
                  <span class="ml-3 my-auto">{{
                    `${merchant_detail.address}, ${merchant_detail.address2}, ${merchant_detail.postcode}`
                  }}</span>
                </div>
                <!-- <div class="mt-4">
                  <v-chip class="ma-2" label color="green accent-1">
                    GetOrders
                  </v-chip>
                  <v-chip class="ma-2" label color="purple accent-1">
                    Uniplacement
                  </v-chip>
                </div> -->
              </div>
            </v-card-text>
          </v-col>
          <v-col cols="6">
            <v-card-title primary-title class="font-weight-bold">
              Password
            </v-card-title>
            <v-card-text>
              <v-text-field label="New Password" outlined dense></v-text-field>
              <v-text-field
                label="Retype Password"
                outlined
                dense
              ></v-text-field>
              <div class="flex justify-end">
                <v-btn color="success">save</v-btn>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card-title primary-title class="font-weight-bold">
              Account Version
            </v-card-title>
            <v-card-text> {{ merchant_detail.v2 ? "V2" : "V1" }}</v-card-text>
          </v-col>
          <v-col cols="6" v-if="merchant_detail.v2">
            <v-card-title primary-title class="font-weight-bold">
              Billplz Bank Account
            </v-card-title>
            <v-card-text>
              <p>Status : {{ merchant_detail.billplz_verified }}</p>
              <p v-if="merchant_detail.billplz_verified == 'rejected'">
                Rejected For : {{ merchant_detail.billplz_reject_desc }}
              </p>
              <p>
                Last Check Account Verification :
                {{ returnDate(merchant_detail.billplz_verified_last_check) }}
              </p>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <h2 class="blue-grey--text nunito lighten-3"><b>Transactions</b></h2>
    <v-divider class="mb-2"></v-divider>
    <v-card outlined color="transparent">
      <v-container grid-list-lg fluid class="nunito">
        <v-row>
          <v-col cols="6">
            <v-card-title primary-title class="font-weight-bold">
              Transaction count
            </v-card-title>
            <v-card-text> 9 </v-card-text>
          </v-col>
          <v-col cols="6">
            <v-card-title primary-title class="font-weight-bold">
              First transaction
            </v-card-title>
            <v-card-text> 22 March 2017 09:22 PM </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card-title primary-title class="font-weight-bold">
              Transaction errors
            </v-card-title>
            <v-card-text> 3 </v-card-text>
          </v-col>
          <v-col cols="6">
            <v-card-title primary-title class="font-weight-bold">
              Latest transaction
            </v-card-title>
            <v-card-text> 22 March 2017 09:22 PM </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      merchant_detail: "getImpersonate",
    }),
  },

  methods: {
    returnDate(i) {
      return dayjs(i).format("DD/MM/YY h:mmA");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  padding: 0px 5px;
}
</style>
